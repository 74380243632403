<template>
  <div id="personCenter" v-wechat-title='title'>
    <LeftFixedPanel type="personCenter"></LeftFixedPanel>
    <div class="right">
      <component :is="component" :loginData="loginData" ></component>
    </div>
  </div>
</template>

<script>
import LeftFixedPanel from './components/LeftFixedPanel.vue'
import MyCollection from './components/MyCollection.vue'
import UserInfoPanel from './components/UserInfoPanel.vue'
export default {
  components: {
    LeftFixedPanel,
    UserInfoPanel,
    MyCollection
  },
  data() {
    return {
      component: null,
      loginData: null
    }
  },
  computed: {
    component() {
      return this.$route.params.type
    },
    title() {
      return this.$route.params.type == 'MyCollection' ? 
      '我收藏的 - 广东省碳达峰碳中和标准化技术信息公共服务平台' :
      '账号设置 - 广东省碳达峰碳中和标准化技术信息公共服务平台'
    }
  },
  watch: {
    '$route.params.type': {
      handler(n) {
        if(n == 'UserInfoPanel') {
          this.showPage()
        }
      },
      immediate: true
    }
  },
  methods: {
    showPage() {
      this.$API.ForntUserDetail().then(res => {
        if(res.data.code == 200) {
          this.loginData = res.data.data;
          this.$store.commit('login_type', 1)
        }else {
          this.$message.error('请先登录!')
          this.$router.replace('/');
        }
      })
    }
  }
}
</script>

<style lang='scss'>
  #personCenter {
    width: 1200px;
    margin: 0 auto;
    .right {
      margin-left: 300px;
    }
    
  }
</style>