<template>
  <div class="userInfo_panel">
    <h3>用户信息</h3>
    <a-form :model="form" :label-col-props="{span: 1}" @submit="handleSubmit" label-align="left" :rules="rules" ref="formRef">
      <a-form-item field="avatarUrl" label="头像" row-class="diff" extra="请上传 200×200 像素的，体积小于 2MB 的 .jpg、.jpeg 或 .png 格式的图片">
        <a-upload
          :custom-request="customRequest"
          :fileList="file ? [file] : []"
          :show-file-list="false"
          @change="onChange"
        >
          <template #upload-button>
            <div
              :class="`arco-upload-list-item${
                file && file.status === 'error' ? ' arco-upload-list-item-error' : ''
              }`"
            >
            <div
              class="arco-upload-list-picture custom-upload-avatar">
              <img :src="file.url || form.avatarUrl" />
              <div class="arco-upload-list-picture-mask">
                <icon-edit />
              </div>
            </div>
          </div>
        </template>
        </a-upload>
      </a-form-item>
      <a-form-item field="username" label="姓名" >
        <a-input size="large" v-model="form.username" placeholder="请输入姓名" :max-length="20" allow-clear show-word-limit />
      </a-form-item>
      <a-form-item field="company" label="公司" >
        <a-input size="large" v-model="form.company" placeholder="请输入公司名称" :max-length="20" allow-clear show-word-limit />
      </a-form-item>
      <a-form-item field="wechat" label="微信号" >
        <a-input size="large" v-model="form.wechat" placeholder="方便参与活动后，平台与您取得联系" :max-length="20" allow-clear show-word-limit />
      </a-form-item>
      <a-form-item>
        <a-button html-type="submit" type="primary">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
const validateName = (value, cb) => {
  let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
  if(value) {
    if(!reg.test(value)) {
      return cb('请输入格式为中文，数字，大小写字母，下划线的姓名');
    }else {
      return cb();
    }
  }else {
    return cb('请输入姓名');
  }
  
}
export default {
  props: ['loginData'],
  data() {
    return {
      form: {
        username: '',
        company: '',
        wechat: '',
        avatarUrl: require('@/assets/img/cchead.jpg')
      },
      rules: {
        name: [
          { validator: validateName, trigger: 'blur' }
        ]
      },
      file: ''
    }
  },
  watch: {
    loginData: {
      handler(n) {
        if(n) {
          this.form = { ...n }
          this.form.avatarUrl = this.form.avatarUrl ? this.baseUrl + this.form.avatarUrl : require('@/assets/img/cchead.jpg')
        }
      }
    }
  },
  methods: {
    onChange(_, currentFile) {
      this.file = {
        ...currentFile,
        url: URL.createObjectURL(currentFile.file),
      };
      this.form.avatarUrl = URL.createObjectURL(currentFile.file)
    },
    customRequest(option) {
      const formData = new FormData();
      formData.append('file', option.fileItem.file);
      formData.append('type', 'IMAGE');
      this.$API.UploadFile( formData ).then(res => {
        this.form.url = res.data.link
      })
    },
    handleSubmit({ errors }) {
      if(errors) return
      else {
        this.$API.EditForntUser({
          avatarUrl: this.form.url,
          company: this.form.company,
          username: this.form.username,
          wechat: this.form.wechat,
          id: this.form.id,
          openId: this.form.openId,
        }).then(res => {
          if(res.data.code == 200) {
            this.$message.success('操作成功')
            this.$store.commit('userInfo_type', null)
            this.$store.commit('userInfo_type', this.form)
          }else {
            this.$message.error(res.data.msg || '操作失败')
          }
        })
      }
    }
  },
}
</script>

<style lang='scss'>
  .userInfo_panel {
    & > h3 {
      height: 48px;
      line-height: 48px;
      padding: 0 10px;
      font-size: 18px;
    }

    .arco-form {
      padding: 20px 10px;
    }

    .arco-col-1 {
      width: 66px;
      display: block;;
      padding-right: 16px;
      line-height: 36px;
      flex: 0 0 66px;
    }

    .arco-form-item-content-wrapper {
      width: 400px;
    }

    .arco-form-item-content，
    .arco-input-wrapper {
      width: 400px;
      height: 36px;
    }

    .arco-input-wrapper .arco-input.arco-input-size-large {
      line-height: 20px;
      padding: 8px 0;
    }

    .arco-upload-list-item {
      margin-top: 0;
    }

    .arco-upload-picture-card {
      width: 120px;
      height: 120px;

      img {
        width: 120px;
        height: 120px;
      }
    }

    .diff {
      .arco-form-item-label {
        line-height: 120px;
      }
    }

    .arco-form-item-extra {
      font-size: 14px;
      color: #909399;
      margin-top: 10px;
      // width: 368px;
      line-height: 20px;

    }

    .arco-upload-list-picture {
      width: 120px;
      height: 120px;
      margin: 0;
    }
    .arco-upload-list-picture-mask {
      line-height: 120px;
    }

    .arco-btn {
      width: 68px;
      height: 36px;
      border-radius: 4px;
      line-height: 36px;
    }

    .arco-avatar {
      background: transparent;
    }
  }
</style>