<template>
  <div class="my_collection">
    <CommonArticleList :dataList="dataList" :disabled="true"></CommonArticleList>
    <CommonPagination
      :pageNum="pageNum"
      :pageSize="pageSize"
      :totalNum="totalNum"
      placement="right"></CommonPagination>
  </div>
</template>

<script>
import CommonPagination from '@/components/CommonPagination.vue'
import CommonArticleList from '@/components/CommonArticleList.vue'
import merge from "webpack-merge";
export default {
  components: {
    CommonPagination,
    CommonArticleList
  },
  data() {
    return {
      pageNum: parseInt(this.$route.query.pager) || 1,
      pageSize: 10,
      totalNum: 0,
      dataList: [],
    }
  },
  watch: {
    pageNum: {
      handler(n, o) {
        if(n != o) {
          this.$router.replace({
            query: merge(this.$route.query, { pager: n }),
          });
        }
      }
    },
    immediate: true
  },
  mounted() {
    if(!this.$route.query.pager) {
      this.$router.replace({
        query: merge(this.$route.query, { pager: this.pageNum }),
      });
    }
    this.showPage()
  },
  methods: {
    showPage() {
      if(!this.$route.query.pager) this.$router.replace('/404')
      this.$API.CollectArticleList({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res => {
        if(res.data.code == 200) {
          this.dataList = res.data.data.list;
          this.totalNum = res.data.data.total;
          if(res.data.data.list.length == 0 && this.pageNum > 1) {
            this.pageNum = 1;
            this.showPage();
          }
        }
      })
    }
  }
}
</script>

<style lang='scss'>
  .my_collection {

  }
</style>