<template>
  <div id="LeftFixedPanel">
    <div class="list">
      <div :class="[{'active': active == item.title}, 'item']" v-for="item in typeList[type]" :key="item.title" @click="chooseClick(item)">
        <svg-icon :icon-class="item.icon" /> {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
import merge from 'webpack-merge'
export default {
  props: ['type'],
  data() {
    return {
      active: '',
      typeList: {
        personCenter: [
          { icon: 'star_border', title: '我收藏的', component: 'MyCollection' },
          { icon: 'account', title: '账号设置', component: 'UserInfoPanel' },
        ]
      }
    }
  },
  mounted() {
    if(!this.$route.params.type) {
      this.active = this.typeList[this.type][0].title
      this.$router.push({
        params:merge(this.$route.params, {'type': this.typeList[this.type][0].component}),
        query:merge(this.$route.query, {'pager': 1}),
      })
    }else {
      let data = this.typeList[this.type].find(item => item.component == this.$route.params.type)
      if(data) {
        this.active = data.title
      }else {
        this.$router.replace('/404')
      }
    }
    
  },
  methods: {
    chooseClick(item) {
      this.active = item.title;
      if(item.component == 'MyCollection') {
        this.$router.push({
          params:merge(this.$route.params, {'type': item.component}),
          query:merge(this.$route.query, {'pager': 1}),
        })
      }else {
        this.$router.push({
          params:merge(this.$route.params, {'type': item.component}),
        })
      }
    }
  }
}
</script>

<style lang="scss">
  #LeftFixedPanel {
    position: fixed;
    width: 280px;
    padding: 0 10px;
    min-height: calc(100vh - 184px - 144px);

    .list {
      .item {
        padding: 15px 10px;
        font-size: 14px;
        color: #1d2129;
        border-radius: 2px;
        display: flex;
        align-items: center;

        .svg-icon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }

        &:hover {
          background: #fafafa;
          cursor: pointer;
        }
      }

      .active {
        background: #f2faf9;
        color: #05A081;
        font-weight: bold;

        .svg-icon {
          color: #05A081;

        }
      }
    }
  }
</style>